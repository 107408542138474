import React, { useState } from "react";
import { Link } from "react-router-dom";
import suitman from "./assets/images/suitman.jpg";
import blackman from "./assets/images/blackman.webp";
import dude from "./assets/images/dude.avif";
import login from "./assets/images/login.png";
import profile from "./assets/images/profile.png";
import welcome from "./assets/images/welcome.png";
import { useTranslation } from "react-i18next";

function LandingPage() {
  const [isDark, setIsDark] = useState(true);
  const { t, i18n } = useTranslation();
  const [showLangDropdown, setShowLangDropdown] = useState(false);

  return (
    <div className={isDark ? "dark" : ""}>
      <div className="bg-yellow-400 text-black text-center py-2 font-semibold">
        {t("androidBanner")}
      </div>
      <div className="min-h-screen bg-white dark:bg-gray-900 text-gray-800 dark:text-gray-200 font-sans relative">
        <div className="absolute top-4 right-[9rem] z-50">
          <button
            onClick={() => setShowLangDropdown((prev) => !prev)}
            className="h-10 px-4 py-2 bg-gray-200 dark:bg-gray-700 rounded focus:outline-none text-xl"
          >
            {i18n.language === "no" ? "🇳🇴" : "🇬🇧"}
          </button>
          {showLangDropdown && (
            <div className="mt-2 bg-white dark:bg-gray-700 rounded shadow absolute w-32">
              <button
                onClick={() => {
                  i18n.changeLanguage("en");
                  setShowLangDropdown(false);
                }}
                className="w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 flex items-center"
              >
                🇬🇧 English
              </button>
              <button
                onClick={() => {
                  i18n.changeLanguage("no");
                  setShowLangDropdown(false);
                }}
                className="w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 flex items-center"
              >
                🇳🇴 Norsk
              </button>
            </div>
          )}
        </div>

        {/* Floating Dark/Light Mode Toggle */}
        <button
          onClick={() => setIsDark(!isDark)}
          className="absolute top-4 right-4 z-50 px-4 py-2 bg-gray-200 dark:bg-gray-700 rounded focus:outline-none"
        >
          {isDark ? "Light Mode" : "Dark Mode"}
        </button>

        {/* Hero Section */}
        <section className="relative px-4 py-20 bg-gradient-to-r from-white to-gray-100 dark:from-gray-800 dark:to-gray-900 text-center">
          <h2 className="text-5xl font-bold font-avenir mb-6">{t("title")}</h2>
          <p className="text-xl italic mt-6 mb-6">{t("subtitle")}</p>
          <p className="max-w-3xl mx-auto mb-8">{t("heroText")}</p>
          <button className="px-8 py-4 bg-[#ff375f] text-white font-semibold rounded-lg hover:bg-pink-600 transition-colors">
            {t("cta")}
          </button>
          {/* Scroll Indicator */}
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2">
            <svg
              className="w-6 h-6 animate-bounce text-[#ff375f]"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </div>
        </section>

        {/* Features Section */}
        <section className="px-4 py-16">
          <div className="max-w-4xl mx-auto">
            <h3 className="text-3xl font-bold mb-8 text-center">
              {t("featuresTitle")}
            </h3>
            <div className="grid gap-8 sm:grid-cols-2">
              <div className="bg-white dark:bg-gray-700 rounded-lg shadow p-6 transition-transform duration-300 hover:scale-105">
                <h4 className="text-xl font-semibold mb-2 text-[#ff375f]">
                  {t("features.taskLogTitle")}
                </h4>
                <p className="text-gray-600 dark:text-gray-300">
                  {t("features.taskLogText")}
                </p>
              </div>
              <div className="bg-white dark:bg-gray-700 rounded-lg shadow p-6 transition-transform duration-300 hover:scale-105">
                <h4 className="text-xl font-semibold mb-2 text-[#ff375f]">
                  {t("features.earnPointsTitle")}
                </h4>
                <p className="text-gray-600 dark:text-gray-300">
                  {t("features.earnPointsText")}
                </p>
              </div>
              <div className="bg-white dark:bg-gray-700 rounded-lg shadow p-6 transition-transform duration-300 hover:scale-105">
                <h4 className="text-xl font-semibold mb-2 text-[#ff375f]">
                  {t("features.monthlyTitle")}
                </h4>
                <p className="text-gray-600 dark:text-gray-300">
                  {t("features.monthlyText")}
                </p>
              </div>
              <div className="bg-white dark:bg-gray-700 rounded-lg shadow p-6 transition-transform duration-300 hover:scale-105">
                <h4 className="text-xl font-semibold mb-2 text-[#ff375f]">
                  {t("features.sharedTitle")}
                </h4>
                <p className="text-gray-600 dark:text-gray-300">
                  {t("features.sharedText")}
                </p>
              </div>
              <div className="bg-white dark:bg-gray-700 rounded-lg shadow p-6 transition-transform duration-300 hover:scale-105">
                <h4 className="text-xl font-semibold mb-2 text-[#ff375f]">
                  {t("features.timelineTitle")}
                </h4>
                <p className="text-gray-600 dark:text-gray-300">
                  {t("features.timelineText")}
                </p>
              </div>
              <div className="bg-white dark:bg-gray-700 rounded-lg shadow p-6 transition-transform duration-300 hover:scale-105">
                <h4 className="text-xl font-semibold mb-2 text-[#ff375f]">
                  {t("features.customTitle")}
                </h4>
                <p className="text-gray-600 dark:text-gray-300">
                  {t("features.customText")}
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* About / How It Works Section */}
        <section className="px-4 py-16 bg-gray-50 dark:bg-gray-800">
          <div className="max-w-4xl mx-auto text-center">
            <p className="text-lg mb-6">{t("howItWorks.p1")}</p>
            <p className="text-lg">{t("howItWorks.p2")}</p>
          </div>
        </section>

        {/* Screenshots Section */}
        <section className="px-4 py-16">
          <div className="max-w-6xl mx-auto">
            <div className="grid gap-4 md:grid-cols-3 justify-items-center">
              <img
                src={login}
                alt="Login Screen"
                className="rounded-lg shadow-md w-48"
              />
              <img
                src={profile}
                alt="Profile Screen"
                className="rounded-lg shadow-md w-48"
              />
              <img
                src={welcome}
                alt="Welcome Screen"
                className="rounded-lg shadow-md w-48"
              />
            </div>
          </div>
        </section>

        {/* Reviews Section */}
        <section className="px-4 py-16 bg-white dark:bg-gray-900">
          <div className="max-w-6xl mx-auto text-center">
            <h3 className="text-3xl font-bold mb-12">{t("reviewsTitle")}</h3>
            <div className="grid gap-12 sm:grid-cols-3">
              {/* Reviewer 1 */}
              <div className="flex flex-col items-center">
                <img
                  src={suitman}
                  alt="Reviewer 1"
                  className="w-24 h-24 rounded-full mb-4 object-cover"
                />
                <div className="flex mb-2">
                  {[...Array(5)].map((_, i) => (
                    <svg
                      key={i}
                      className={`w-5 h-5 ${
                        i < 1 ? "text-yellow-400" : "text-gray-300"
                      }`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.967a1 1 0 00.95.69h4.174c.969 0 1.371 1.24.588 1.81l-3.38 2.455a1 1 0 00-.364 1.118l1.287 3.967c.3.921-.755 1.688-1.54 1.118l-3.38-2.455a1 1 0 00-1.175 0l-3.38 2.455c-.784.57-1.838-.197-1.539-1.118l1.287-3.967a1 1 0 00-.364-1.118L2.05 9.394c-.783-.57-.38-1.81.588-1.81h4.174a1 1 0 00.95-.69l1.286-3.967z" />
                    </svg>
                  ))}
                </div>
                <p className="text-gray-600 dark:text-gray-300">
                  {t("review1")}
                </p>
              </div>
              {/* Reviewer 2 */}
              <div className="flex flex-col items-center">
                <img
                  src={blackman}
                  alt="Reviewer 2"
                  className="w-24 h-24 rounded-full mb-4 object-cover"
                />
                <div className="flex mb-2">
                  {[...Array(5)].map((_, i) => (
                    <svg
                      key={i}
                      className={`w-5 h-5 ${
                        i < 5 ? "text-yellow-400" : "text-gray-300"
                      }`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.967a1 1 0 00.95.69h4.174c.969 0 1.371 1.24.588 1.81l-3.38 2.455a1 1 0 00-.364 1.118l1.287 3.967c.3.921-.755 1.688-1.54 1.118l-3.38-2.455a1 1 0 00-1.175 0l-3.38 2.455c-.784.57-1.838-.197-1.539-1.118l1.287-3.967a1 1 0 00-.364-1.118L2.05 9.394c-.783-.57-.38-1.81.588-1.81h4.174a1 1 0 00.95-.69l1.286-3.967z" />
                    </svg>
                  ))}
                </div>
                <p className="text-gray-600 dark:text-gray-300">
                  {t("review2")}
                </p>
              </div>
              {/* Reviewer 3 */}
              <div className="flex flex-col items-center">
                <img
                  src={dude}
                  alt="Reviewer 3"
                  className="w-24 h-24 rounded-full mb-4 object-cover"
                />
                <div className="flex mb-2">
                  {[...Array(5)].map((_, i) => (
                    <svg
                      key={i}
                      className={`w-5 h-5 ${
                        i < 4 ? "text-yellow-400" : "text-gray-300"
                      }`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.967a1 1 0 00.95.69h4.174c.969 0 1.371 1.24.588 1.81l-3.38 2.455a1 1 0 00-.364 1.118l1.287 3.967c.3.921-.755 1.688-1.54 1.118l-3.38-2.455a1 1 0 00-1.175 0l-3.38 2.455c-.784.57-1.838-.197-1.539-1.118l1.287-3.967a1 1 0 00-.364-1.118L2.05 9.394c-.783-.57-.38-1.81.588-1.81h4.174a1 1 0 00.95-.69l1.286-3.967z" />
                    </svg>
                  ))}
                </div>
                <p className="text-gray-600 dark:text-gray-300">
                  {t("review3")}
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* About the Developer Section */}
        <section className="px-4 py-16 bg-gray-100 dark:bg-gray-800">
          <div className="max-w-4xl mx-auto text-center">
            <h3 className="text-3xl font-bold mb-4">{t("aboutDevTitle")}</h3>
            <p className="text-lg">{t("aboutDevText")}</p>
            <a
              href="https://www.linkedin.com/in/simen"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block mt-4"
            >
              <svg
                className="w-8 h-8 inline-block text-blue-700"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M19 0h-14c-2.761 0-5 2.238-5 5v14c0 2.762 2.239 5 5 5h14c2.762 0 5-2.238 5-5v-14c0-2.762-2.238-5-5-5zm-11.75 20h-3v-11h3v11zm-1.5-12.271c-.966 0-1.75-.78-1.75-1.742 0-.963.784-1.742 1.75-1.742.967 0 1.75.779 1.75 1.742 0 .962-.783 1.742-1.75 1.742zm13.25 12.271h-3v-5.604c0-1.337-.026-3.058-1.865-3.058-1.868 0-2.155 1.459-2.155 2.964v5.698h-3v-11h2.882v1.501h.041c.402-.762 1.382-1.565 2.843-1.565 3.039 0 3.604 2.001 3.604 4.605v6.459z" />
              </svg>
            </a>
          </div>
        </section>

        {/* Footer */}
        <footer className="py-6 px-4 text-center">
          <p>
            &copy; {new Date().getFullYear()} {t("footerText")}
          </p>
          <Link
            to="/terms"
            className="mt-4 inline-block px-4 py-2 border border-gray-300 dark:border-gray-600 rounded hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors"
          >
            {t("terms")}
          </Link>
        </footer>
      </div>
    </div>
  );
}

export default LandingPage;
